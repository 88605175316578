<!-- 
author:张洪志
description: 基金详情
 -->
<template>
  <section class="fund-detail">
    <site-nav>
      <span>&gt;</span>
      <RouterLink :to="{name:'FundMarket'}">基金超市</RouterLink>
      <span>&gt;</span>
      <span>{{fundName}}</span>
    </site-nav>
    <div class="fund-base flex-between vw">
      <div class="base-left">
        <div class="title-box">
          <span class="title">{{fundName}}</span>
          <span class="tag" v-if="privateFlagType">{{privateFlagType}}</span>
        </div>
        <div class="code orange">基金代码 <big>{{fundCode}}</big></div>
        <div class="type">
          <span class="btn-orange-light" v-if="fundType">{{fundType}}</span>
          <span class="btn-orange-2" v-if="riskLevel">
            <span class="ico-risk"></span>
            <span> {{riskLevel}}</span>
          </span>
          <span class="btn-orange-light" v-if="fundShareType">{{fundShareType}}</span>
        </div>
        <div class="rate-box flex-between" v-if="this.ofundType != '5'">
          <div class="item">
            <p :class="['value',nvdailygrowthrate<0? 'green': 'red']" v-if="nvdailygrowthrate">
              <span>{{nvdailygrowthrate}}</span>
              <small>%</small>
            </p>
            <p>日涨跌幅</p>
          </div>
          <div class="item">
            <p>{{tradingday}}</p>
            <p class="value orange">{{unitnv}}</p>
            <p>最新单位净值</p>
          </div>
          <div class="item">
            <p :class="['value',rrinsingleyear<0? 'green': 'red']" v-if="rrinsingleyear">
              <span>{{rrinsingleyear}}</span>
              <small>%</small>
            </p>
            <p>近一年涨跌幅</p>
          </div>
        </div>
        <div class="rate-box flex-between" v-if="this.ofundType == '5'">
          <div class="item">
            <p :class="['value',latestweeklyyield<0? 'green': 'red']" v-if="latestweeklyyield">
              <span>{{latestweeklyyield}}</span>
              <small> %</small>
            </p>
            <p>七日年化</p>
          </div>
          <div class="item">
            <p :class="['value',dailyprofit<0? 'green': 'red']" v-if="dailyprofit">
              <span>{{dailyprofit}}</span>
              <small> 元</small>
            </p>
            <p>万份收益</p>
          </div>
        </div>
        <div class="summary flex-between">
          <dl class="flex-between">
            <dt>基金类型:</dt>
            <dd>{{fundType}} | {{riskLevel}}</dd>
          </dl>
          <dl class="flex-between">
            <dt>成立日期:</dt>
            <dd>{{establishmentdate}}</dd>
          </dl>
          <dl class="flex-between">
            <dt>基金规模:</dt>
            <dd>{{foundedsize}}元</dd>
          </dl>
          <dl class="flex-between">
            <dt>基金经理:</dt>
            <dd>{{manager}}</dd>
          </dl>
        </div>
        <div class="summary flex-between">
          <dl class="flex-between">
            <dt>基金名称:</dt>
            <dd class="blue">{{fundFullName}}</dd>
          </dl>
          <dl class="flex-between">
            <dt>基金管理人:</dt>
            <dd class="blue btn" @click="goCompany">{{fundCompany}}</dd>
          </dl>
        </div>
        <div class="income-box">
          <div class="title" v-if="this.ofundType != '5'">
            <span :class="['btn',{'current':incomeIndex === 1}]" @click="incomeChange(1)">业绩走势</span>
            <span :class="['btn',{'current':incomeIndex === 2}]" @click="incomeChange(2)">单位净值</span>
            <span :class="['btn',{'current':incomeIndex === 3}]" @click="incomeChange(3)">累计净值</span>
          </div>
          <div class="title" v-if="this.ofundType == '5'">
            <span :class="['btn',{'current':incomeIndex === 4}]" @click="incomeChange(4)">七日年化</span>
            <span :class="['btn',{'current':incomeIndex === 5}]" @click="incomeChange(5)">万份收益</span>
          </div>
          <div class="body">
            <div class="flex-between tab" v-show="incomeIndex === 1">
              <span :class="['btn',{'current':unitValue_1Time === 1}]" @click="getUnitValue_1(1)">近1月</span>
              <span :class="['btn',{'current':unitValue_1Time === 2}]" @click="getUnitValue_1(2)">近3月</span>
              <span :class="['btn',{'current':unitValue_1Time === 3}]" @click="getUnitValue_1(3)">近6月</span>
              <span :class="['btn',{'current':unitValue_1Time === 4}]" @click="getUnitValue_1(4)">近一年</span>
              <span :class="['btn',{'current':unitValue_1Time === 5}]" @click="getUnitValue_1(5)">近三年</span>
              <span :class="['btn',{'current':unitValue_1Time === 6}]" @click="getUnitValue_1(6)">成立以来</span>
            </div>
            <div class="flex-between tab" v-show="incomeIndex === 2">
              <span :class="['btn',{'current':unitValue_2Time === 1}]" @click="getUnitValue_2(1)">近1月</span>
              <span :class="['btn',{'current':unitValue_2Time === 2}]" @click="getUnitValue_2(2)">近3月</span>
              <span :class="['btn',{'current':unitValue_2Time === 3}]" @click="getUnitValue_2(3)">近6月</span>
              <span :class="['btn',{'current':unitValue_2Time === 4}]" @click="getUnitValue_2(4)">近一年</span>
              <span :class="['btn',{'current':unitValue_2Time === 5}]" @click="getUnitValue_2(5)">近三年</span>
              <span :class="['btn',{'current':unitValue_2Time === 6}]" @click="getUnitValue_2(6)">成立以来</span>
            </div>
            <div class="flex-between tab" v-show="incomeIndex === 3">
              <span :class="['btn',{'current':unitValue_3Time === 1}]" @click="getUnitValue_3(1)">近1月</span>
              <span :class="['btn',{'current':unitValue_3Time === 2}]" @click="getUnitValue_3(2)">近3月</span>
              <span :class="['btn',{'current':unitValue_3Time === 3}]" @click="getUnitValue_3(3)">近6月</span>
              <span :class="['btn',{'current':unitValue_3Time === 4}]" @click="getUnitValue_3(4)">近一年</span>
              <span :class="['btn',{'current':unitValue_3Time === 5}]" @click="getUnitValue_3(5)">近三年</span>
              <span :class="['btn',{'current':unitValue_3Time === 6}]" @click="getUnitValue_3(6)">成立以来</span>
            </div>
            <div class="flex-between tab" v-show="incomeIndex === 4">
              <span :class="['btn',{'current':unitValue_4Time === 1}]" @click="getUnitValue_4(1)">近1月</span>
              <span :class="['btn',{'current':unitValue_4Time === 2}]" @click="getUnitValue_4(2)">近3月</span>
              <span :class="['btn',{'current':unitValue_4Time === 3}]" @click="getUnitValue_4(3)">近6月</span>
              <span :class="['btn',{'current':unitValue_4Time === 4}]" @click="getUnitValue_4(4)">近一年</span>
              <span :class="['btn',{'current':unitValue_4Time === 5}]" @click="getUnitValue_4(5)">近三年</span>
              <span :class="['btn',{'current':unitValue_4Time === 8}]" @click="getUnitValue_4(8)">近五年</span>
              <span :class="['btn',{'current':unitValue_4Time === 9}]" @click="getUnitValue_4(9)">今年以来</span>
              <span :class="['btn',{'current':unitValue_4Time === 6}]" @click="getUnitValue_4(6)">成立以来</span>
            </div>
            <div class="flex-between tab" v-show="incomeIndex === 5">
              <span :class="['btn',{'current':unitValue_5Time === 1}]" @click="getUnitValue_5(1)">近1月</span>
              <span :class="['btn',{'current':unitValue_5Time === 2}]" @click="getUnitValue_5(2)">近3月</span>
              <span :class="['btn',{'current':unitValue_5Time === 3}]" @click="getUnitValue_5(3)">近6月</span>
              <span :class="['btn',{'current':unitValue_5Time === 4}]" @click="getUnitValue_5(4)">近一年</span>
              <span :class="['btn',{'current':unitValue_5Time === 5}]" @click="getUnitValue_5(5)">近三年</span>
              <span :class="['btn',{'current':unitValue_5Time === 8}]" @click="getUnitValue_5(8)">近五年</span>
              <span :class="['btn',{'current':unitValue_5Time === 9}]" @click="getUnitValue_5(9)">今年以来</span>
              <span :class="['btn',{'current':unitValue_5Time === 6}]" @click="getUnitValue_5(6)">成立以来</span>
            </div>
            <e-chart :width="710" :height="560" :option="unitValue_1" v-show="incomeIndex === 1" />
            <e-chart :width="710" :height="560" :option="unitValue_2" v-show="incomeIndex === 2" />
            <e-chart :width="710" :height="560" :option="unitValue_3" v-show="incomeIndex === 3" />
            <e-chart :width="710" :height="560" :option="unitValue_4" v-show="incomeIndex === 4" />
            <e-chart :width="710" :height="560" :option="unitValue_5" v-show="incomeIndex === 5" />
          </div>
        </div>
      </div>
      <div class="base-right">
        <div class="flex-between" style="align-items: flex-end;">
          <p>
            <span style="font-size: 17px;">交易规则</span>
            <span class="ico-fund-safe orange" style="margin: 0 5px;"></span>
            <span>民生银行全程资金监管</span>
          </p>
          <p>
            <small class="gray btn" @click="showTradeRule">买入/卖出规则 </small>
            <span>&gt;</span>
          </p>
        </div>
        <div style="padding:30px 0;">
          <p style="padding-left: 20px;">
            <img src="@/static/img/fund-market/detail_1.png" />
          </p>
          <p class="small">
            <span class="fr">{{ date.$format() }}净值更新后</span>
            <span>今日15点前</span>
            <span style="margin-left: 30px;">{{ date.$format() }}({{date.$week()}})</span>
          </p>
        </div>
        <div class="buy-box">
          <div class="flex-between buy-box-up">
            <div style="border-right: 1px solid #ddd;">
              <p class="red" v-if="discountRate"><big>{{discountRate.toFixed(2).$trimZero()}}%</big></p>
              <p class="gray" style="margin-bottom: 1em; font-size: 20px;" v-else>免手续费</p>
              <p v-if="rate"><s>{{rate.toFixed(2).$trimZero()}}%</s></p>
              <p class="gray">购买手续费</p>
            </div>
            <div class="orange">
              <p><big>{{subStartAmt}}</big>元</p>
              <p>&nbsp;</p>
              <p>起购金额</p>
            </div>
          </div>
          <div class="flex-evenly buy-box-down" v-if="viewx!='true' && viewx!=true">
            <a @click="buyFund(1)" :class="['btn btn-orange', {'disable stop-buy': buyStatus === '0'}]">买入</a>
            <a @click="buyFund(2)" :class="['btn btn-orange-plain', {'disable stop-buy': buyStatus === '0'}]">定投</a>
          </div>
        </div>
        <div class="notice small gray flex-between" v-if="false">
          <span class="ico-right orange-light" style="font-size: 1.6em;"></span>
          <p style="margin-left: 6px;">
            确认即表示已仔细阅读并同意
            <a :href="agree1" class="orange">《产品概要》</a>、
            <a :href="agree2" class="orange">《基金合同》</a>、
            <a :href="agree3" class="orange">《招募说明书》</a>、
            <a :href="agree4" class="orange">《风险揭示书》</a>和
            <a :href="agree5" class="orange">《客户维护费揭示书》</a>
            等
          </p>
        </div>
        <div style="padding-top: 25px; margin-top: 25px; border-top: 1px solid #f8e4c4;"></div>
        <div class="grow-box">
          <div class="title flex-between">
            <span :class="['btn',{'btn-orange':growIndex===1}]" @click="changeGrow(1)">阶段涨幅</span>
            <span :class="['btn',{'btn-orange':growIndex===2}]" @click="changeGrow(2)">季度涨幅</span>
            <span :class="['btn',{'btn-orange':growIndex===3}]" @click="changeGrow(3)">年度涨幅</span>
          </div>
          <div v-show="growIndex === 1">
            <div class="data-title flex-between">
              <span>阶段</span>
              <span>涨幅</span>
            </div>
            <div class="data-list">
              <div class="flex-between" v-for="item in incomeData_1" :key="item.name">
                <span>{{item.name}}</span>
                <span :class="item.isGreen ? 'green' : 'red'">{{item.value}}%</span>
              </div>
            </div>
          </div>
          <div v-show="growIndex === 2">
            <div class="data-title flex-between">
              <span>日期</span>
              <span>季度涨幅</span>
            </div>
            <div class="data-list">
              <div class="flex-between" v-for="item in incomeData_2" :key="item.quarterName">
                <span>{{item.quarterName}}</span>
                <span :class="item.isGreen ? 'green' : 'red'">{{item.gr}}%</span>
              </div>
            </div>
          </div>
          <div v-show="growIndex === 3">
            <div class="data-title flex-between">
              <span>日期</span>
              <span>年度涨幅</span>
            </div>
            <div class="data-list">
              <div class="flex-between" v-for="item in incomeData_3" :key="item.yearName">
                <span>{{item.yearName}}</span>
                <span :class="item.isGreen ? 'green' : 'red'">{{item.gr}}%</span>
              </div>
            </div>
          </div>
        </div>
        <div class="value-box">
          <div class="title flex-between">
            <span>{{ ofundType == '5' ? '历史七日年化' : '历史净值' }}</span>
            <small class="gray btn" @click="showValues">{{ ofundType == '5' ? '更多年化收益' : '更多净值' }}</small>
          </div>
          <div class="data-title flex-between">
            <span>日期</span>
            <span>{{ ofundType == '5' ? '万份收益' : '单位净值' }}(元)</span>
          </div>
          <div class="data-list">
            <div class="data-title flex-between" v-for="item in incomeData_4" :key="item.enddate">
              <span>{{item.enddate}}</span>
              <span>{{item.unitnv}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fund-info vw">
      <div class="tab flex-between">
        <span :class="{'current':detailIndex===1}" @click="detailIndex=1">基金概况</span>
        <span :class="{'current':detailIndex===2}" @click="detailIndex=2">基金经理</span>
        <span :class="{'current':detailIndex===3}" @click="detailIndex=3">基金公司</span>
        <span :class="{'current':detailIndex===4}" @click="detailIndex=4">基金持仓</span>
        <span :class="{'current':detailIndex===5}" @click="detailIndex=5">基金公告</span>
        <span :class="{'current':detailIndex===6}" @click="detailIndex=6">交易费率</span>
        <span :class="{'current':detailIndex===7}" @click="detailIndex=7">基金分红</span>
        <span :class="{'current':detailIndex===8}" @click="detailIndex=8">财务指标</span>
      </div>
       <div class="pannel">
        <keep-alive>
          <component :is="detailView" :fundCode="fundCode"></component>
        </keep-alive>
        <div class="notice btn-orange-light big-txt small">
          郑重声明:
          <br>
          以上信息(包括但不限于文字、视频、音频、数据及图表)均基于公开信息采集，相关信息并未经过本公司证实，本公司不保证该信息全部或者部分内容的准确性、真实性、完整性，不构成本公司任何推荐或保证，基金具体信息以管理人相关公告为准，投资者投资前需仔细阅读《基金合同》、《招募说明书》等法律文件，了解产品收益与风险特征，过往业绩不预示其未来表现，市场有风险，投资需谨慎。数据来源：恒生聚源数据。
        </div>
       </div>
    </div>
    <div class="fund-rule">
      <fund-detail-rule :fundCode="fundCode" ref="fundRule" />
    </div>
    <fund-values :fundCode="fundCode" :fundName="fundName" :fundType="ofundType" ref="fundValues" />
    
    <DinDetails :showmodel="showBuyConfirm" @cancels="showBuyConfirm=false" @showdelte="showBuyConfirm=false" @confirms="buyConfirm">
      {{buyConfirmTxt}}
    </DinDetails>
  </section>
</template>

<script>
import SiteNav from '@/views/fund-market/components/SiteNav.vue'
import eChart from '@/components/eChart.vue'
import DinDetails from '@/components/Models.vue'
import FundDetailRule from '@/views/fund-market/components/FundDetailRule.vue'
import FundValues from '@/views/fund-market/components/FundValues.vue'
import FundDetail_1 from '@/views/fund-market/components/FundDetail-1.vue'
import FundDetail_2 from '@/views/fund-market/components/FundDetail-2.vue'
import FundDetail_3 from '@/views/fund-market/components/FundDetail-3.vue'
import FundDetail_4 from '@/views/fund-market/components/FundDetail-4.vue'
import FundDetail_5 from '@/views/fund-market/components/FundDetail-5.vue'
import FundDetail_6 from '@/views/fund-market/components/FundDetail-6.vue'
import FundDetail_7 from '@/views/fund-market/components/FundDetail-7.vue'
import FundDetail_8 from '@/views/fund-market/components/FundDetail-8.vue'
import {getDicts} from '@/api/dict.js'
import {protPage} from '@/api/other/index.js'
import {baseInfo,fundRate2,prodUnit,stageIncome,fundOverview,fundTradeRule} from '@/api/fund.js'
export default {
  name: 'FundDetail',
  components: {
    SiteNav,eChart,FundDetailRule,FundValues,FundDetail_1,FundDetail_2,FundDetail_3,FundDetail_4,FundDetail_5,FundDetail_6,FundDetail_7,FundDetail_8,DinDetails
  },
  props: {
    fundCode: {
      type: String,
      required: true 
    }
  },
  data() {
    return {
      incomeIndex: 1,
      tradeRuleVisible: false,
      growIndex: 1,
      
      detailIndex: 1,
      
      fundTypes:[],
      riskLevels:[],
      fundShareTypes:[],
      fundShareType: '',
      
      /** 基本信息 */
      fundName:'',
      privateFlagType: '',
      fundType:'',
      ofundType: '',
      riskLevel: '',
      ofundRisklevel: '',
      nvdailygrowthrate:'',
      latestweeklyyield:'',
      dailyprofit:'',
      unitnv:'',
      tradingday:'',
      rrinsingleyear:'',
      establishmentdate:'',
      foundedsize:'',
      manager:'',
      fundFullName:'',
      fundCompany:'',
      trusteename: '',
      
      /** 业绩走势 */
      unitValue_1Time:1,
      unitValue_2Time:1,
      unitValue_3Time:1,
      unitValue_4Time:1,
      unitValue_5Time:1,
      unitValue_1:{},
      unitValue_2:{},
      unitValue_3:{},
      unitValue_4:{},
      unitValue_5:{},
      
      /** 费率信息 */
      buyConfirmDate: '',
      discountRate: 0,
      rate: 0,
      subStartAmt: '0.01',
      
      /** 基金协议 */
      agree1:'',
      agree2:'',
      agree3:'',
      agree4:'',
      agree5:'',
      
      /** 阶段涨幅 */
      incomeData_1:[],
      incomeData_2:[],
      incomeData_3:[],
      incomeData_4:[],
      
      date: null,
      
      showBuyConfirm: false,
      buyConfirmTxt: '',
      buyConfirmStep: 0,
      buyAction:'',
      
      buyStatus:'1'
    }
  },
  computed: {
    detailView() {
      switch (this.detailIndex){
        case 2:
          return FundDetail_2
        case 3:
          return FundDetail_3
        case 4:
          return FundDetail_4
        case 5:
          return FundDetail_5
        case 6:
          return FundDetail_6
        case 7:
          return FundDetail_7
        case 8:
          return FundDetail_8
        default:
          return FundDetail_1
      }
    },
    viewx() {
      return this.$route.query.view
    }
  },
  created() {
    this.ofundType = this.$route.params.fundType
    this.incomeIndex = this.ofundType == '5' ? 4 : 1
    
    let date = new Date()
    date.$confirmDate()
    this.date = date
    
    this.getFundType().then(() => {
      this.getRiskLevel()
      this.getFundShareType()
    }).then(() => {      
      this.baseInfo().then(() => {
        if(this.ofundType != '5') {
          this.getUnitValue_1(1)
          this.getUnitValue_2(1)    
          this.getUnitValue_3(1)
        }else {
          this.getUnitValue_4(1)
          this.getUnitValue_5(1)
        }
      })
      this.stageIncome()
      this.fundOverview()
    })
    this.fundRate()
    
    //this.fundTradeRule()
    
    this.getProtocol()
  },
  methods: {
    /** 基本信息 */
    baseInfo() {
      return baseInfo(this.fundCode).then(result => {
        let data = result.data
        this.fundName = data.fundName
        //this.privateFlagType = data.privateFlag === '0' ? '开放式' : '封闭式'
        this.fundType = (this.fundTypes.find(m => m.code === data.ofundType)||{}).name
        this.riskLevel = (this.riskLevels.find(m => m.code === data.ofundRisklevel)||{}).name
        this.fundShareType = (this.fundShareTypes.find(m => m.code === data.shareType)||{}).name        
        this.nvdailygrowthrate = data.nvdailygrowthrate ? parseFloat(data.nvdailygrowthrate).toFixed(2) : ''
        this.tradingday = (data.tradingday||'').$formatDate()
        this.unitnv = data.unitnv
        this.rrinsingleyear = data.rrinsingleyear ? parseFloat(data.rrinsingleyear).toFixed(2) : ''
        this.ofundRisklevel = data.ofundRisklevel
        this.ofundType = data.ofundType
        this.latestweeklyyield = data.latestweeklyyield ? (parseFloat(data.latestweeklyyield)).toFixed(2) : ''
        this.dailyprofit = data.dailyprofit ? data.dailyprofit.$trimZero(4) : ''
        this.incomeIndex = this.ofundType == '5' ? 4 : 1
      })
    },
    /** 获取费率 */
    fundRate() {
      fundRate2(this.fundCode,'1').then(result => {
        let data = result.data
        if(data) {
          //this.buyConfirmDate = data.
          data.rate = parseFloat(data.rate || 0)
          data.discountRate = parseFloat(data.discountRate || 100) / 100
          this.discountRate = data.rate * data.discountRate
          this.rate = data.rate
          if(data.discountRate === 1) {
            this.discountRate = this.rate
            this.rate = 0
          }
          this.subStartAmt = data.subStartAmt||0.01
        }
      })
    },
    /** 切换业绩走势 */
    incomeChange(index) {
      this.incomeIndex = index
    },
    /** 单位净值 */
    getUnitValue_1(time) {
      this.unitValue_1Time = time
      //let start = this.calcTimeStart(time)
      prodUnit(this.fundCode,'3',time).then(result => {
        let data = result.data
        let x = Array.from(data, m => m.endData)
        let y = Array.from(data, m => m.unit)
        let optionData = {
          x , y
        }
        this.unitValue_1 = this.getLineOption(optionData,1)
      })
    },
    /** 单位净值 */
    getUnitValue_2(time) {
      this.unitValue_2Time = time
      //let start = this.calcTimeStart(time)
      prodUnit(this.fundCode,'1',time).then(result => {
        let data = result.data
        let optionData = {
          x : Array.from(data, m => m.endData),
          y : Array.from(data, m => m.unit)
        }        
        this.unitValue_2 = this.getLineOption(optionData,2)
      })
    },
    /** 累计净值 */
    getUnitValue_3(time) {
      this.unitValue_3Time = time
      //let start = this.calcTimeStart(time)
      prodUnit(this.fundCode,'2',time).then(result => {
        let data = result.data
        let optionData = {
          x : Array.from(data, m => m.endData),
          y : Array.from(data, m => m.unit)
        }
        this.unitValue_3 = this.getLineOption(optionData,3)
      })
    },
    /** 七日年化率 */
    getUnitValue_4(time) {
      this.unitValue_4Time = time
      let start = this.calcTimeStart(time)
      prodUnit(this.fundCode,'4',time,start).then(result => {
        let data = result.data
        let optionData = {
          x : Array.from(data, m => m.endData),
          y : Array.from(data, m => parseFloat((m.unit).toFixed(2)))
        }
        this.unitValue_4 = this.getLineOption(optionData,4)
      })
    },
    /** 万份收益 */
    getUnitValue_5(time) {
      this.unitValue_5Time = time
      let start = this.calcTimeStart(time)
      prodUnit(this.fundCode,'5',time,start).then(result => {
        let data = result.data
        let optionData = {
          x : Array.from(data, m => m.endData),
          y : Array.from(data, m => m.unit)
        }
        this.unitValue_5 = this.getLineOption(optionData,5)
      })
    },
    /** 获取交易规则 */
    fundTradeRule() {
      fundTradeRule(this.fundCode).then(result => {
        let prodTradeRule = result.data.prodTradeRule
        if(prodTradeRule) {
          //this.subStartAmt = prodTradeRule.subStartAmt
          //this.buyConfirmDate = prodTradeRule.buyConfirmDate
        }
      })
    },
    /** 获取产品协议 */
    getProtocol() {
      getDicts('protocol_name').then(result => {
        let dicts = result.data||[]
        protPage({pageSize:100,fundCode:this.fundCode}).then(result => {
          let data = result.data.data || []
          
          let id1 = (dicts.find(m => m.dictLabel == '《产品概要》')||{}).dictValue || ''
          this.agree1 = (data.find(m => m.protName === id1)||{}).protUrl
          let id2 = (dicts.find(m => m.dictLabel == '《基金合同》')||{}).dictValue || ''
          this.agree2 = (data.find(m => m.protName === id2)||{}).protUrl
          let id3 = (dicts.find(m => m.dictLabel == '《招募说明书》')||{}).dictValue || ''
          this.agree3 = (data.find(m => m.protName === id3)||{}).protUrl
          let id4 = (dicts.find(m => m.dictLabel == '《风险揭示书》')||{}).dictValue || ''
          this.agree4 = (data.find(m => m.protName === id4)||{}).protUrl
          let id5 = (dicts.find(m => m.dictLabel == '《客户维护费揭示书》')||{}).dictValue || ''
          this.agree5 = (data.find(m => m.protName === id5)||{}).protUrl
        })
      })
    },
    /** 获取阶段收益 */
    stageIncome() {
      stageIncome(this.fundCode,1,10).then(result => {
        let data = result.data
        this.incomeData_1 = [
          {name:'较前日单位净值涨幅',value: (parseFloat(data.nvGrPre1d||0)).toFixed(2),isGreen: parseFloat(data.nvGrPre1d) < 0},
          {name:'近一月单位净值涨幅',value: (parseFloat(data.nvGrPre1m||0)).toFixed(2),isGreen: parseFloat(data.nvGrPre1m) < 0},
          {name:'近一年单位净值涨幅',value: (parseFloat(data.nvGrPre1y||0)).toFixed(2),isGreen: parseFloat(data.nvGrPre1y) < 0},
          {name:'今年以来单位净值涨幅',value: (parseFloat(data.nvGrPreTy||0)).toFixed(2),isGreen: parseFloat(data.nvGrPreTy) < 0},
          {name:'成立以来单位净值涨幅',value: (parseFloat(data.nvGrPreMin||0)).toFixed(2),isGreen: parseFloat(data.nvGrPreMin) < 0}
        ]
        
        data.netvalueQuarterList.filter(m => m.gr !== null).forEach(m => {
          m.gr = (parseFloat(m.gr)).toFixed(2)
          m.isGreen = parseFloat(m.gr) < 0
        })
        this.incomeData_2 = data.netvalueQuarterList
        
        data.netvalueYearList.filter(m => m.gr !== null).forEach(m => {
          m.gr = (parseFloat(m.gr)).toFixed(2)
          m.isGreen = parseFloat(m.gr) < 0
        })
        this.incomeData_3 = data.netvalueYearList
        
        data.data.forEach(m => {
          m.enddate = m.enddate.$formatDate()
          m.unitnv = m.unitnv.$trimZero()
          m.accumulatedunitnv = m.accumulatedunitnv.$trimZero()
          m.nvdailygrowthrate = (parseFloat(m.nvdailygrowthrate)).toFixed(2)          
          m.isGreen = parseFloat(m.nvdailygrowthrate) < 0
        })
        this.incomeData_4 = data.data
      })
    },
    /** 基金概况 */
    fundOverview() {
      fundOverview(this.fundCode).then(result => {
        let data = result.data
        let fundBase = data.fundBase
        this.privateFlagType = fundBase.issuefundtype_desc
        this.establishmentdate = fundBase.establishmentdate
        this.foundedsize = (parseFloat(fundBase.foundedsize)).$formatBig(4)
        this.manager = fundBase.manager
        this.fundFullName = fundBase.chiname
        this.fundCompany = fundBase.investadvisorname
        this.trusteename = fundBase.trusteename
        this.buyStatus = fundBase.buyStatus
      })
    },    
    /** 获取份额类别 */
    getFundShareType() {
     return getDicts('prod_fund_share_type').then(result => {
      if(!result.data) {
        return
      }
      this.fundShareTypes = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
     })
    }, 
		/** 获取基金类型 */
		getFundType() {
			return getDicts('sys_fund_type').then(result => {
				if(!result.data) {
					return
				}
				this.fundTypes = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
			})
		},
		/** 获取基金风险级别 */
		getRiskLevel() {
			return getDicts('sys_fund_risklevel').then(result => {
				if(!result.data) {
					return
				}
				this.riskLevels = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
			})
		},
    /** 显示基金公司 */
    goCompany() {
      this.detailIndex=3
      this.$nextTick(() => {
        document.documentElement.scrollTop = 1000
      })
    },
    /** 显示交易规则 */
    showTradeRule() {
      this.tradeRuleVisible = true
      this.$refs.fundRule.show = true
    },
    /** 切换涨幅周期 */
    changeGrow(index) {
      this.growIndex = index
    },
    /** 显示历史净值列表 */
    showValues() {
      this.$refs.fundValues.toggleVisible(true)
      this.$refs.fundValues.stageIncome()
    },
    /** 计算业绩开始时间 */
    calcTimeStart(time) {
      let now = new Date()
      let day = 24*60*60*1e3
      switch (time){
        case 2:
          return new Date(now-90*day).$format('yyyyMMdd')
        case 3:
          return new Date(now-182*day).$format('yyyyMMdd')
        case 4:
          return new Date(now-365*day).$format('yyyyMMdd')
        case 5:
          return new Date(now-3*365*day).$format('yyyyMMdd')
        case 6:
          return this.establishmentdate.replace(/'-'/g,'')
        case 8:
          return new Date(now-5*365*day).$format('yyyyMMdd')
        case 9:
          return now.getFullYear()+'0101'
        default:
          return new Date(now-30*day).$format('yyyyMMdd')
      }
    },
    /** 获取线性图参数 */
    getLineOption(data,type) {
      let option = {
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          }
        },
        xAxis: {
          data: data.x,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLabel:{}
        },
        series: [
          {
            data: data.y,
            type: 'line',
            showSymbol: false,
            areaStyle:{
              color:'#f30',
              opacity:0.3
            },
            itemStyle: {
              color: '#f00'
            },
            lineStyle: {
              width: 1
            }
          }
        ]
      }
      if(type === 1) {
        option.tooltip.formatter = '涨跌幅: {c}%<br>日期: {b}'
        option.yAxis.axisLabel.formatter = '{value}%'
      }else if(type === 2) {
        option.tooltip.formatter = '单位净值: {c}<br>日期: {b}'
      }else if(type === 3){
        option.tooltip.formatter = '累计净值: {c}<br>日期: {b}'
      }else if(type === 4){
        option.tooltip.formatter = '收益率: {c}%<br>日期: {b}'
        option.yAxis.axisLabel.formatter = '{value}%'
      }else{
        option.tooltip.formatter = '日收益: {c}元<br>日期: {b}'
      }
      return option
    },
    /** 购买基金 */
    buyFund(action) {
      if(this.buyStatus === '0') {
        return;
      }
      this.buyConfirmStep = 0
      let token = this.$store.getters.token;
      let _userInfo = localStorage.getItem("customerInfo");
      if (!token || !_userInfo) {
        this.buyConfirmTxt = '请登录!'
        this.buyConfirmStep = 1
      }else {
        let userInfo = JSON.parse(_userInfo);
        if (!userInfo.acctFlag || userInfo.acctFlag == "0") {
          this.buyConfirmTxt = '请先开户!'
          this.buyConfirmStep = 2
        }else if (!userInfo.addDataFlag || userInfo.addDataFlag == "0") {
          this.buyConfirmTxt = '请补充资料!'
          this.buyConfirmStep = 3
        }else if (!userInfo.tradePwdFlag || userInfo.tradePwdFlag == "0") {
          this.buyConfirmTxt = '请设置交易密码!'
          this.buyConfirmStep = 4
        }else if (!userInfo.riskFlag || userInfo.riskFlag == "0") {
          this.buyConfirmTxt = '请先完成风险测评!'
          this.buyConfirmStep = 5
        }else if(userInfo.riskLevel < this.ofundRisklevel){
          this.buyConfirmTxt = '当前产品风险等级高于您的风险测评等级，确认购买？'
          this.buyConfirmStep = 6
        }
      }
      this.buyAction = action
      if(!this.buyConfirmStep) {
        this.buyConfirm()
        return
      }      
      this.showBuyConfirm = true
    },
    /** 购买基金确认 */
    buyConfirm() {
      this.showBuyConfirm = false
      if(this.buyConfirmStep === 1) { 
        this.$router.push({ name: "Login" })
        return
      }
      if(this.buyConfirmStep === 2) { 
        this.$router.push({ name: "OpenAccount" });
        return
      }
      if(this.buyConfirmStep === 3) { 
        this.$router.push({ name: "PersonalDataSupply" });
        return
      }
      if(this.buyConfirmStep === 4) { 
        this.$router.push({ name: "OpenAccount" });
        return
      }
      if(this.buyConfirmStep === 5) { 
        this.$router.push({ name: "RiskAssessment" });
        return
      }
      let actionName = this.buyAction === 1 ? 'ProcessPurchase' : 'AddProcess'
      this.$router.push({name:actionName,params:{fundCode:this.fundCode}})
    }
  }
}
</script>

<style lang="less" scoped>
  @gray: #999;
  @gray-bg: #F3F4F6;
  @orange:#CE9B63;
  @green:#01B834;
  @red:#D43F3F;
  @blue:#00aaff;
  .gray {color:@gray;}
  .gray-bg { background: @gray-bg;}
  .orange{ color:@orange; }
  .orange-light{ color:#f8e4c4; }
  .green{color:@green;}
  .red{color:@red;}
  .blue{ color:@blue;}
  .btn-orange{ background: #F1CDA8; color:#BF8A4E; border-radius: 3px; text-align: center;}
  .btn-orange-light{ background: #FFF5EA; color:#C59660; border-radius: 3px;}
  .btn-orange-2{ background: #F6D7B4; background-image: linear-gradient(90deg,#F6D7B4,#ECC39B); color:#fff; border-radius: 3px;}
  .btn-orange-plain{ border:1px solid #F1CDA8; color:#BF8A4E; border-radius: 3px; text-align: center;}
  .small{font-size:12px;}
  
  .fund-detail{ .gray-bg();}
  
  .fund-base{ padding:40px 0 10px 0;  justify-content: center;
    .base-left{width:750px; margin-right: 30px;
      .title-box{
        .title{font:bold 22px "microsoft yahei";}
        .tag{.btn-orange(); padding:4px 8px; margin-left:1.2em;}
      }
      .code{ line-height: 3em;}
      .type>span{ padding:4px 8px; margin-right: 12px; font-size:12px;}
      .rate-box{ padding:20px; .gray(); line-height: 40px; justify-content: flex-start; align-items: flex-end;
        .item{padding-right: 60px; margin-right: 60px;}
        .item:first-child{ border-right: 1px solid #ddd;}
        .item:last-child{ border-left: 1px solid #ddd; padding-left:60px; margin-left: -60px;}
        .value{font:bold 24px "microsoft yahei";}
      }
      .summary{ line-height: 3em;
        dt{.gray(); margin-right: 1em;}
      }
      .income-box{ padding-top: 2em;
        .title{font-size:16px; border-bottom: 1px solid #eee; display: flex;
          &>span{ padding:10px 48px;}
          &>.current{.gray-bg();}
        }
        .body{ margin-top:30px; background: #FBFBFB; padding:20px; height:560px;.gray();
          .tab{ background: #fff; padding:10px;
            span{ padding:0.3em 1.5em;}
            .current{.btn-orange();}
          }
        }
      }
    }
    
    .base-right{width:360px;
      .buy-box-up{ text-align: center;
        &>div{width:49%;}
        big{font-size: 30px;}
      }
      .buy-box-down{ margin-top:20px;
        .btn{width:44%; line-height: 2.2em; font-size: 1.3em;}
      }
      .notice{ align-items: center; padding:25px 0;}
      .grow-box{ margin-top: 5px;
        .title{border-bottom:1px solid #f8e4c4;
          &>span{ width:33%; border-radius: 1px; line-height: 2.5em; font-size:16px;}
        }
        .data-title{ background: #FFF5EA; line-height: 2.5em; .orange(); margin-top:20px;
          &>span{padding:0 3em;}
        }
        .data-list{ line-height: 3em; font-size: 1.1em; overflow: auto; height: 282px;
          &>div{border-bottom: 1px solid #eee; padding:0 2.4em 0 1.5em;
            &>span:first-child{ color:#666;}
            &>span:last-child{ font-weight: bold;}
          }
          &::-webkit-scrollbar { width:12px; }
          &::-webkit-scrollbar-track {background: #FFF5EA;}
          &::-webkit-scrollbar-thumb { border-radius:10px; background:#F0D8BD;}
        }
      }
      .value-box{ margin-top:10px;
        .title{ line-height: 3em; font-size:16px; align-items: flex-end;}
        .data-title{.gray-bg(); line-height: 3em; font-size: 15px;
          &>span{width:33%; text-align: center;}
        }
        .data-list{ height: 214px; overflow:auto;
          &>div{ background: #fff; border-bottom: 1px solid #eee; color:#666;
            &>span:last-child{.red();font-weight: bold;}
          }
          &::-webkit-scrollbar { width:12px; }
          &::-webkit-scrollbar-track {.gray-bg();}
          &::-webkit-scrollbar-thumb { border-radius:10px; background:#E0E2E8;}
        }
      }
    }
  }
  
  .fund-info{ padding-top: 30px;
    .tab{ font:15px "microsoft yahei"; .gray(); justify-content: space-around; line-height: 2.8em; border-bottom: 1px solid #eee;
      &>span{cursor: pointer;}
      &>.current{ font:bold 16px/2.8em "microsoft yahei"; .orange(); border-bottom: 3px solid @orange; margin-bottom: -1px;}
    }
    .pannel{ padding:0 30px 30px 30px;
      .title{font-size:20px; line-height: 3em; margin-top: 10px;
        .ico{ margin:0 5px;}
      }
      .table2{ line-height: 3em; font-size:15px; border-top:1px solid #eee;
        dl{border-bottom: 1px solid #eee;}
        dt{.gray-bg(); width:6em; padding:0 1em;}
        dd{width:460px; padding-left: 1em;}
      }
      .big-txt{ line-height: 2em; text-indent: 2em;}
      .notice{ text-indent: 0; padding:1em; margin-top: 1em;;}
    }
  }
  
  .fund-rule{ position: absolute; top:0; left:0; width:100%; background: rgba(255,255,255,0.5); z-index: 4;}
</style>
